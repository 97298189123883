import * as React from "react";
import { Helmet } from "react-helmet";
import { globalHistory } from "@reach/router";

import Template from "../components/Template";

import * as styles from "./research.module.scss";

const ResearchPage = ({ location }) => {
  console.log(globalHistory.location.pathname);
  const pageLang = !globalHistory.location.pathname.includes("/fr")
    ? "en"
    : "fr";

  console.log(pageLang);
  const show = (lang) => {
    if (lang === "en" && pageLang === "en") return { display: "block" };
    else if (lang === "fr" && pageLang === "fr") return { display: "block" };
    else return { display: "none" };
  };

  return (
    <Template location={location}>
      <Helmet>
        <title>{`Charih / ${
          pageLang === "en" ? "Research" : "Recherche"
        }`}</title>
      </Helmet>
      <h1 style={show("fr")}>Recherche</h1>
      <h1 style={show("en")}>Research</h1>
      <div className={styles.introduction} style={show("en")}>
        <h2>In a nutshell</h2>
        <p>
          I am currently a PhD student in Electrical and Computer Engineering
          specializing in computational biology. I am particularly interested in
          applications of{" "}
          <em>artificial intelligence (AI)/machine learning (ML)</em> to design
          novel therapeutic peptides and study lysine methylation. I have also
          developed an interest in <em>high performance computing</em>{" "}
          throughout the past years. My thesis advisors are Profs.{" "}
          <a href="http://www.sce.carleton.ca/faculty/green/green.php">
            James R. Green
          </a>{" "}
          (Systems and Computer Engineering) and{" "}
          <a href="https://carleton.ca/biology/people/kyle-biggar/">
            Kyle K. Biggar
          </a>{" "}
          (Biochemistry).
        </p>
        {/*<p>
          Beyond my thesis work, I am also interested in how AI/ML can be
          applied to further our understanding of the pathophysiology and
          clinical evolution of{" "}
          <a href="https://en.wikipedia.org/wiki/Amyotrophic_lateral_sclerosis">
            amyotrophic lateral sclerosis (ALS)
          </a>
          , an understudied illness of the motoneurons that has eluded us for
          way too long.
        </p>*/}
      </div>
      <div className={styles.currentResearch} style={show("en")}>
        <h2>Thesis work</h2>
        <p>
          As part of my thesis work, I apply AI/ML tools to the study of lysine
          methylation. My research can be subdivided into three research themes:
          <ol className={styles.thesisTopics}>
            <li>
              <b>
                Proteome-wide identification of the lysine methylation sites
              </b>
              <br />
              Our cells express over 20,000 proteins, a large portion of which
              are substrates in a set of chemical reactions called{" "}
              <em>post-translational modifications</em> (phosphorylation,
              acetylation, methylation, etc.) These reactions can modulate the
              function, stability and localization within the cell. I aim to
              develop new machine learning approaches to answer questions such
              as:{" "}
              <i>
                What proteins are methylated? How does this relate to other
                modifications? What are the implications of this modification?
              </i>
            </li>
            <li>
              <b>
                Improving the computational efficiency of <i>in silico</i>{" "}
                design of lysine methyltransferase inhibitors
              </b>
              <br />I am improving upon the state-of-the-art algorithms for
              peptide inhibitor design using sequence information only, as
              existing algorithms are computationally intensive and not
              scalable. We are developing a fast algorithm named <i>Darwin</i>{" "}
              which leverages large databases of protein-protein interactions
              active peptides capable of specifically modulating the activity of
              lysine methyltransferases (KMTs) and lysine demethylases (KDMs).
              Overactive KMTs/KDMs are involved in a number of cancers, and
              these peptides could supplement existing therapies and improve
              their efficacy.
            </li>
            <li>
              <b>
                Optimizing the design process for difficult-to-target proteins
              </b>
              <br />
              Sequence-based peptide engineering methods rely on validated
              protein-protein interactions (PPIs). In some cases, databases of
              known PPIs provide insightful information that can guide the
              peptide design process. In other cases, the finite set of known
              interactions is insufficient as the target may bear little
              similarity to proteins with known interactors. I am trying to
              improve the search strategy in a very large peptide space in order
              to probe it more efficiently using deep learning techniques
              (protein language models).
            </li>
          </ol>
        </p>
        <img className={styles.abstractImage} src="/img/thesis_overview.png" />
      </div>
      <div className={styles.pastResearch} style={show("en")}>
        <h2>Previous work</h2>
        <p>
          <ol className={styles.previousTopics}>
            <li>
              <b>Structural biology</b>
              <br />
              Under the supervision of Prof.{" "}
              <a href="https://med.uottawa.ca/bmi/en/people/couture-jean-francois">
                Jean-François Couture
              </a>
              , I completed my{" "}
              <a href="/Charih_Honours_2015.pdf">my honours thesis</a> on the
              structural characterisation of the Fur (Ferric Uptake Regulator)
              protein in <i>Campylobacter jejuni</i> a pathogenic agent
              responsible for numerous cases of gastroenteritis. I crystallized
              the protein and built a draft model of the structure, which was
              further refined by Prof. Couture. This research was published in
              the scientific journal{" "}
              <a href="https://www.nature.com/articles/s41598-018-25157-6">
                Scientific Reports
              </a>
              .
            </li>
            <li>
              <b>Biomedical informatics</b>
              <br />I have previously worked in health informatics. My master's
              thesis work focused on the ML-assisted classification of
              audiograms to facilitate their interpretation by non-experts. My
              thesis is available{" "}
              <a href="https://curve.carleton.ca/873548bb-f077-49d4-a5a6-9a69fddf1284">
                here
              </a>
              .
            </li>
          </ol>
        </p>
      </div>
      {/* ================= FRANCAIS ==================================== */}
      <div className={styles.introduction} style={show("fr")}>
        <h2>En bref</h2>
        <p>
          Je suis présentement doctorant en génie électrique et informatique,
          quoique je me spécialise en <em>biologie computationnelle</em>. Je
          m'intéresse tout particulièrement aux applications de{" "}
          <em>l'apprentissage automatique</em> dans la conception d'inhibiteurs
          peptidiques et l'étude de la méthylation des lysines. Dans le cadre de
          mes projets de recherches, j'utilise des techniques de{" "}
          <em>calcul haute performance (high performance computing)</em>. Mes
          directeurs de thèse sont les professeurs{" "}
          <a href="http://www.sce.carleton.ca/faculty/green/green.php">
            James R. Green
          </a>{" "}
          (département de génie des systèmes et informatique) et{" "}
          <a href="https://carleton.ca/biology/people/kyle-biggar/">
            Kyle K. Biggar
          </a>{" "}
          (département de biochimie).
        </p>
        {/*<p>
          Je m'intéresse aussi, au-delà de mon sujet principal de thèse, aux
          applications de l'intelligence artificielle et de l'apprentissage
          automatique à la compréhension de l'évolution pathophysiologique et
          clinique de la <em>sclérose latérale amyotrophique</em>, une maladie
          grave des neurones moteurs qui nous échappe depuis beaucoup trop
          longtemps.
        </p>*/}
      </div>
      <div className={styles.currentResearch} style={show("fr")}>
        <h2>Travaux de thèse</h2>
        <p>
          Dans le cadres de mes travaux de thèse, je m'intéresse aux
          applications de l'IA pour l'étude de la méthylation des lysines. Mes
          travaux se déclinent en trois axes de recherche:
          <ol className={styles.thesisTopics}>
            <li>
              <b>
                Identification du méthylome des lysines à l'échelle du protéome
                avec l'IA
              </b>
              <br />
              Nos cellules expriment au-delà de 20,000 différentes protéines qui
              sont sujettes à des modifications chimiques appellées{" "}
              <em>modifications post-traductionnelles</em> (phosphorylation,
              acétylation, méthylation, etc.) qui altèrent leur fonction, leur
              stabilité et leur localisation dans la cellule.{" "}
              <i>
                Quelles protéines sont sujettes à la méthylation sur leurs
                lysines? Quel est le lien entre la méthylation et la fonction?
              </i>
            </li>
            <li>
              <b>
                Développement d'algorithmes de conception d'inhibiteurs
                peptidiques ciblant les (dé)méthylases de lysines plus
                performants
              </b>
              <br />
              J'itère sur l'état de l'art en algorithmes de conception
              d'inhibiteurs peptidiques. Les algorithmes qui sont informés par
              les séquences en acides aminés qui existent présentement ont des
              coûts de calculs très élevés. Nous développons un algorithme plus
              performant nommé <i>Darwin</i> qui fait usage de grandes quantités
              de données sur les interactions entre les protéines pour concevoir
              des inhibiteurs de (dé)méthylases des lysines. Ces enzymes sont
              surexprimées dans un nombre important de cancers et nous
              anticipons que ces peptides pourraient être utilisés comme
              thérapies adjuvantes pour augmenter l'efficacité des traitements
              actuels.
            </li>
            <li>
              <b>
                Optimisation de l'algorithme de conception d'inhibiteur
                peptidiques pour les protéines difficiles à cibler
              </b>
              <br />
              Les algorithmes de conception d'inhibiteurs peptidiques qui
              utilisent la structure primaire des protéines font usage de
              banques de données répertoriant les interactions entre protéines.
              Dans certains cas, les protéines ciblées ont peu de similarité
              avec les protéines ayant des interactions connues, ce qui rend la
              conception d'inhibiteurs plus difficile. J'essaie donc d'optimiser
              la stratégie avec laquelle l'espace (infiniment grand) des
              peptides est exploré pour converger aussi rapidement que possible
              vers des peptides capables d'interagir avec la cible en utilisant
              des techniques d'apprentissage profond (modèle de langage des
              protéines).
            </li>
          </ol>
        </p>
        <img className={styles.abstractImage} src="/img/thesis_overview.png" />
      </div>
      <div className={styles.pastResearch} style={show("fr")}>
        <h2>Travaux antérieurs</h2>
        <p>
          <ol className={styles.previousTopics}>
            <li>
              <b>Biologie structurale</b>
              <br />
              Sous la supervision du professeur{" "}
              <a href="https://med.uottawa.ca/bmi/en/people/couture-jean-francois">
                Jean-François Couture
              </a>
              , j'ai complété{" "}
              <a href="/Charih_Honours_2015.pdf">ma thèse de premier cycle</a>{" "}
              sur la caractérisation structurale de la protéine Fur (Ferric
              uptake regulator) dans C. jejuni. J'ai participé à la résolution
              de la structure de la protéine. Les résultats des travaux auxquels
              j'ai contribué ont été publiés dans la revue scientifique{" "}
              <a href="https://www.nature.com/articles/s41598-018-25157-6">
                Scientific Reports
              </a>
              .
            </li>
            <li>
              <b>Informatique biomédicale</b>
              <br />
              J'ai complété des travaux en informatique biomédicale dans le
              cadre de mon projet de maîtrise. Je me suis intéressé à la
              classification automatique de courbes audiométriques pour
              faciliter leur interprétation par des professionnels de la santé
              non-spécialistes. Je vous invite à consulter mon mémoire de
              maîtrise (en anglais){" "}
              <a href="https://curve.carleton.ca/873548bb-f077-49d4-a5a6-9a69fddf1284">
                disponible ici
              </a>
              .
            </li>
          </ol>
        </p>
      </div>
    </Template>
  );
};

export default ResearchPage;
